import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

interface SubHeaderProps {
  href: string;
  buttonText: string;
  isBackButton?: boolean;
  showBackButton?: boolean;
  logo?: boolean;
}

const SubHeaderComponent: React.FC<SubHeaderProps> = ({ href, buttonText, isBackButton, showBackButton = true, logo }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (isBackButton) {
      navigate(-1); // Regresar a la página anterior
    }
  };

  return (
    <header className="subheader">
      <div className="container">
        <div className="row">
          <div className="subheader__col col-12">
            {showBackButton && <div>
              {isBackButton ? (
              <Link to="#" className="back-link" onClick={handleClick}>
                <span className="material-icons-outlined">chevron_left</span>
                Regresar
              </Link>
            ) : (
              <a href={href} className="btn btn--arrow-left">
                <span className="material-icons-outlined icon">arrow_back</span> <span>{buttonText}</span>
              </a>
            )  }
            </div>}
            
            {logo !== false &&
              <figure className="subheader__logo">
                <img src="/assets/images/logo/soma-white.svg" alt="SOMA+" />
              </figure>
            }
          </div>
        </div>
      </div>
    </header>
  );
};

export default SubHeaderComponent;
