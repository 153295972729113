import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import SubHeaderComponent from "../../../components/shared/Subheader/subHeader.component";
import { StripeProduct } from "../../../types/stripe";
import {
  getStripeProducts,
  renewStripeSubscription,
} from "../../../api/stripeApi";
import LoaderComponent from "../../../components/shared/Loader/Loader.component";
import { getUser, searchUser } from "../../../api/usersApi";
import { Modal } from "react-bootstrap";

const RenewMembershipScreen = () => {
  const navigate = useNavigate();
  const { customerId } = useParams();
  const [selected, setSelected] = useState("");
  const [products, setProducts] = useState<StripeProduct[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedPaymentType, setSelectedPaymentType] = useState("one_time_s");
  const [clientSecret, setClientSecret] = useState("");

  const stripe = useStripe();
  const elements = useElements();

  const setError = (error: string) => {
    console.log("error", error);
    Swal.fire({
      icon: "error",
      title: "Error",
      text: error,
    });
  };

  const validateUserData = async () => {
    try {
      if (!customerId) {
        navigate("/login");
        return;
      }
      const search = await searchUser(
        0,
        1,
        "",
        { stripeCustomerId: customerId },
        ""
      );
      if (!search.docs || !search.docs.length) {
        navigate("/login");
        return;
      }
      const user = search.docs[0];
      const userData = await getUser(user._id || "", "");
      if (userData.stripeCustomerId !== customerId) {
        navigate("/login");
        return;
      }
      const { stripeSubscription } = userData;
      if (stripeSubscription && stripeSubscription.length) {
        const ableToRenew = stripeSubscription.some(
          (sub) => sub.renewCandidate === true
        );
        if (!ableToRenew) {
          return Swal.fire({
            icon: "error",
            title: "Error",
            text: "Tu suscripción no puede ser renovada ya que aún se encuentra activa.",
          }).then(() => {
            navigate("/login");
          });
        }
      }
      await fetchStripeProducts();
    } catch (error) {
      setError((error as any).message);
    }
  };

  const fetchStripeProducts = async () => {
    try {
      const products = await getStripeProducts("");
      if (products) {
        setProducts(products);
        setIsLoading(false);
      }
    } catch (error) {
      setError((error as any).message);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    const { error: submitError } = await elements.submit();
    if (submitError) {
      console.log("handleSubmit submitError.message", submitError.message);
      setError(
        submitError.message ||
          "Error al procesar el pago. Por favor, inténtalo de nuevo."
      );
      return;
    }
    const { error } = await stripe.confirmSetup({
      elements,
      clientSecret,
      confirmParams: {
        return_url: "https://app.somaplus.com/login",
      },
      redirect: "if_required",
    });
    if (error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log("handleSubmit stripe.confirmSetup", error);
      setError("Error al procesar el pago. Por favor, inténtalo de nuevo.");
    } else {
      setIsLoading(false);
      setClientSecret("");
      showConfirmation();
    }
  };

  const showConfirmation = () => {
    Swal.fire({
      icon: "success",
      title: "!Felicidades!",
      text: "Tu suscripción ha sido renovada con éxito.",
      allowEscapeKey: false,
      allowOutsideClick: false,
    }).then(() => {
      navigate("/login");
    });
  };

  const handleRenew = async () => {
    try {
      setIsLoading(true);
      const price = selected;
      if (customerId && price) {
        const result = await renewStripeSubscription("", {
          customer: customerId,
          price,
        });
        if (result) {
          const { subscription } = result;
          if (subscription) {
            const { pending_setup_intent } = subscription;
            if (pending_setup_intent) {
              const { status } = pending_setup_intent;
              if (status && status === "requires_payment_method") {
                const { client_secret } = pending_setup_intent;
                setClientSecret(client_secret);
                return;
              }
            }
          }
          showConfirmation();
        }
      }
    } catch (error) {
      setError((error as any).message);
    }
  };

  useEffect(() => {
    validateUserData();
  }, []);

  return (
    <>
      <SubHeaderComponent
        href={"/login"}
        buttonText={"Regresar"}
        showBackButton={false}
      />
      <section className="pt-4 mt24 membership-layout">
        <div className="container">
          <div className="row membership--top">
            {clientSecret && (
              <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                show={clientSecret !== ""}
                className="modal--card"
              >
                <Modal.Header>
                  <Modal.Title id="contained-modal-title-vcenter">
                    <h2 className="h4 mb-0 text-base text-icon">
                      <i className="fa fa-lock" />
                      <span className="ml-2">Confirmación de pago</span>
                    </h2>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="form-row form--head">
                    <div className="col-12">
                      <p className="text-base">
                        Por favor, confirma tu pago para renovar tu membresía.
                      </p>
                      <div className="form-group">
                        <form id="payment-form" onSubmit={handleSubmit}>
                          <div className="form-row">
                            <PaymentElement />
                          </div>
                          <button
                            type="submit"
                            className="btn btn--type1 w-100"
                            disabled={!stripe}
                          >
                            <span id="button-text">Confirmar pago</span>
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            )}
            <div className="membership__col-left col-md-4 col-12">
              <div className="form-row form--head">
                <h1 className="h2 text-gray350 mb-4">
                  Es momento de renovar tu membresia para seguir formando parte
                  de SOMA+.
                </h1>
                <p>
                  Elige la forma de pago y plazo que prefieras, y asegura tu
                  renovación hoy.
                </p>
              </div>
              {isLoading && (
                <div className="mb-4">
                  <LoaderComponent isLoading={isLoading} />
                </div>
              )}
              <div className="form-row tab-change">
                <div
                  className={`form-check form-check-inline ${
                    selectedPaymentType === "one_time_s" ? "active" : ""
                  }`}
                >
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    value="one_time_s"
                    checked={selectedPaymentType === "one_time_s"}
                    onChange={(e) => setSelectedPaymentType(e.target.value)}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio1">
                    Pago único
                  </label>
                </div>
                <div
                  className={`form-check form-check-inline ${
                    selectedPaymentType === "monthly" ? "active" : ""
                  }`}
                >
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    value="monthly"
                    checked={selectedPaymentType === "monthly"}
                    onChange={(e) => setSelectedPaymentType(e.target.value)}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio2">
                    Cuotas
                  </label>
                </div>
              </div>
              {products
                .map((product) =>
                  product.prices.data.filter((price) => {
                    const { metadata } = price;
                    if (metadata) {
                      const { payment_type } = metadata;
                      return payment_type === selectedPaymentType;
                    }
                    return false;
                  })
                )
                .flat() // Aplanamos el array de arrays
                .sort((a, b) => {
                  const { metadata: metadataA } = a;
                  const { metadata: metadataB } = b;
                  if (metadataA && metadataB) {
                    const { order: orderA } = metadataA;
                    const { order: orderB } = metadataB;
                    if (orderA && orderB) {
                      return orderA - orderB;
                    }
                  }
                  return 0;
                })
                .map((price, index) => {
                  const { metadata } = price;
                  return (
                    <div className="form-row form--renew" key={index}>
                      <div
                        className={`form-check check--left check--block ${
                          selected === price.id ? "active" : ""
                        }`}
                      >
                        <input
                          className="form-check-input"
                          type="radio"
                          name="membership"
                          value="yearly"
                          id={`membership-${index}`}
                          checked={selected === price.id}
                          onChange={() => setSelected(price.id)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`membership-${index}`}
                        >
                          {`${metadata.Description}`}
                        </label>
                      </div>
                    </div>
                  );
                })}
            </div>
            <aside className="membership__col-right col-md-8 col-12">
              <figure className="image">
                <img
                  src="/assets/images/elements/frame-membership.png"
                  alt="Membership"
                />
              </figure>
            </aside>
          </div>
          <div className="row membership--bottom">
            <div className="mx-auto mb-3 text-center col-md-8 col-12">
              <button
                type="button"
                className="btn btn--type1 btn--large"
                disabled={selected !== "" ? false : true}
                onClick={handleRenew}
              >
                Pagar
              </button>
            </div>
            <div className="mx-auto text-center col-md-8 col-12">
              <a href="/login" className="btn btn--type2 btn--large">
                Completar más tarde
              </a>
            </div>
            <div className="col-12">
              <ul className="pb-5 list-terms">
                <li>
                  <a href="/terms">Términos de uso</a>
                </li>
                <li>
                  <a href="/privacy">Aviso de privacidad</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RenewMembershipScreen;
