import React, { useContext, useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { Reservations } from "../../types/entidades";
import { newReservation } from "../../api/reservationsApi";
import { Movie, VersionsId } from "../../types/cinemas";
import { getMovie } from "../../api/cinemasApi";
import AppContext from "../../context/appContext";
import { es } from "date-fns/locale";
import HeaderBottom from "../../components/shared/HeaderBottom/HeaderBottom.component";
import SubHeaderComponent from "../../components/shared/Subheader/subHeader.component";
import { getUnixTime } from "date-fns";
import { getHorariosReserva, isBetween } from "../../utils/utils";

const RequestMovieScreen = () => {
  registerLocale("es", es);

  const userInfo = useContext(AppContext).userInfo;
  const { movieId } = useParams();
  const [time, setTime] = useState<Date | null>(null);
  const [movie, setMovie] = useState<Movie | null>(null);
  const [movieFilters, setMovieFilters] = useState<
    {
      movieId: number;
      label: string;
      selected?: boolean;
    }[]
  >([]);
  const [successRequest, setSuccessRequest] = useState<boolean | null>(null);
  const [reservation, setReservation] = useState<Partial<Reservations>>({
    place: "",
    type: "Entertainment",
    attendees: 1,
    time: undefined,
  });
  const [dates, setDates] = useState<any>([]);

  const setError = (errorMessage: string) => {
    console.log("errorMessage", errorMessage);
    Swal.fire("Error", `${errorMessage}`, "error");
  };

  const fetchMovie = async (jwtToken: string, idCinema: string) => {
    try {
      const response = await getMovie(jwtToken, idCinema);
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const getMovieFilters = (movie: Movie) => {
    if (movie?.versionsIds) {
      const filters = movie.versionsIds.map((version: VersionsId, i) => ({
        movieId: Number(version._id),
        label: version.label,
        selected: i === 0,
      }));
      setMovieFilters(filters);
    }
  };

  const checkValidMovie = () => {
    if (movie?.versionsIds) {
      const { time } = reservation;
      const valid = movie.versionsIds.some((version: VersionsId) => {
        return version.sessionsIds.some((showtime) => {
          console.log("SHOWTIME", { showtime, time });
          return showtime.date === time;
        });
      });
      return valid;
    }
    return false;
  };

  const checkHorariosReserva = async () => {
    try {
      const response = await getHorariosReserva();
      if (response) {
        const { horaInicio, horaFin, alerta } = response;
        const checkIsBetween = isBetween(horaInicio, horaFin);
        if (!checkIsBetween) {
          setError(alerta || "No se pueden realizar reservas en este horario");
        }
        return checkIsBetween;
      }
    } catch (error) {
      setError("Error al obtener los horarios");
    }
    return false;
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!reservation.date) {
      Swal.fire("Error", "Selecciona el día y hora de la función", "error");
      return;
    }
    if (reservation.attendees === undefined) {
      Swal.fire("Error", "Selecciona el número de personas a asistir", "error");
      return;
    }
    const check = await checkHorariosReserva();
    if (check) {
      try {
        const result = await newReservation(reservation, userInfo?.token || "");
        if (result) {
          setSuccessRequest(true);
        }
      } catch (error) {
        setError(`${error}`);
      }
    }
  };

  const getDates = (versions: any) => {
    let newDates: any = [];

    versions.map((version: any) => {
      version.sessionsIds.map((showtime: any) => {
        const dateComplete = new Date(showtime.datetime);
        const dateShow = showtime.datetime.split("T")[0];
        newDates.push({
          date: dateShow,
          hour: dateComplete,
          version: version.label,
        });
        return null;
      });

      return null;
    });
    console.log(newDates);

    setDates(newDates);
    return newDates;
  };

  const filterDate = (date: any) => {
    return dates.some(
      (item: any) => item.date === date.toISOString().split("T")[0]
    );
  };

  const includeTimes = (date: Date) => {
    // Date format yyyy-mm-dd
    const dateFormatted = date.toISOString().split("T")[0];
    const excludeHours: any = [];
    console.log("includeTimes DATES", dates);

    dates.forEach((item: any) => {
      // Get date with format yyyy-mm-dd
      const time = new Date(item.hour);

      if (dateFormatted && item.date.startsWith(dateFormatted)) {
        const filterSelected = movieFilters.filter((filter) => filter.selected);
        if (item.version === filterSelected[0].label) {
          console.log("TIME", item);
          // native  setHours(setMinutes(new Date(), 0), 17),
          excludeHours.push(time);
        }
      }
    });

    return excludeHours;
  };

  const onChangeDate = (selectedDate: Date | null) => {
    if (selectedDate !== null) {
      setReservation((rsv: any) => ({
        ...rsv,
        date: selectedDate,
        time: getUnixTime(selectedDate),
      }));
      setTime(selectedDate);
    }
  };

  useEffect(() => {
    fetchMovie(userInfo?.token ?? "", movieId as string).then((res) => {
      const response = res as Movie;
      setMovie(response);
      getMovieFilters(response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // console.log(reservation);
    if (movie?.versionsIds) {
      // if (movie.cinema_id.name !== reservation.place) {
      setReservation((res) => ({
        ...res,
        place: movie.cinema_id.name + " | " + movie.name,
        userId: userInfo?._id,
        type: "Entertainment",
      }));
      // }
      getDates(movie?.versionsIds);
    }
  }, [movie?.versionsIds]);

  useEffect(() => {
    checkValidMovie();
  }, [reservation]);

  return (
    <>
      <SubHeaderComponent
        isBackButton
        logo={false}
        buttonText="Regresar"
        href=""
      />
      <HeaderBottom children={<>Nueva Reservación</>} />
      <section className="dashboard-layout">
        <section className="module">
          <div className="container">
            <div className="row">
              {!successRequest && (
                <div className="col-md-10 col-12 mx-auto">
                  <div className="row justify-content-between">
                    {movie && (
                      <>
                        <div className="col-md-7 col-12 mx-auto mb-4 mb-md-0">
                          <img
                            src={movie.poster_big}
                            alt={movie.name}
                            className="promo-image"
                          />
                        </div>
                        <div className="col-md-5 col-12 mx-auto d-flex align-items-center">
                          <form onSubmit={handleSubmit}>
                            <div className="row">
                              <div className="col-12 mb-4">
                                <div className="movie-info position-sticky">
                                  <h4 className="mb-0">
                                    {movie.cinema_id.name}
                                  </h4>
                                  <h2 className="mb-0">
                                    <strong>{movie.name}</strong>
                                  </h2>
                                  <h5>{movie.label}</h5>
                                </div>
                              </div>
                              {movieFilters.length > 1 ? (
                                <div className="col-12 mb-4">
                                  <div className="form-control-picker">
                                    <select
                                      className="form-control"
                                      onChange={(e) => {
                                        setMovieFilters(
                                          movieFilters.map((filter) => ({
                                            ...filter,
                                            selected:
                                              filter.movieId ===
                                              Number(e.target.value),
                                          }))
                                        );
                                      }}
                                    >
                                      {movieFilters.map((filter) => (
                                        <option
                                          key={filter.movieId}
                                          value={filter.movieId}
                                          selected={filter.selected}
                                        >
                                          {filter.label}
                                        </option>
                                      ))}
                                    </select>
                                    <span className="icon version" />
                                  </div>
                                </div>
                              ) : null}
                              <div className="col-12 mb-4">
                                <div className="form-control-picker">
                                  <DatePicker
                                    startDate={new Date()}
                                    minDate={new Date()}
                                    filterDate={filterDate}
                                    selected={reservation?.date}
                                    onChange={(date: any) => onChangeDate(date)}
                                    className="form-control"
                                    placeholderText="Seleccionar fecha"
                                    required={true}
                                    locale={"es"}
                                    showDisabledMonthNavigation
                                    onKeyDown={(e) => {
                                      e.preventDefault();
                                    }}
                                  />
                                  <span className="icon date" />
                                </div>
                                <div
                                  className="alert alert-danger hidden"
                                  role="alert"
                                >
                                  La fecha es obligatoria
                                </div>
                              </div>
                              <div className="position-relative col-12 mb-4">
                                <div className="form-control-picker">
                                  <DatePicker
                                    selected={time}
                                    onChange={(time: any) => {
                                      console.log("TIME", time);
                                      setTime(time);
                                      setReservation((rsv: any) => ({
                                        ...rsv,
                                        time: getUnixTime(time),
                                      }));
                                    }}
                                    includeTimes={includeTimes(
                                      reservation?.date || new Date()
                                    )}
                                    timeIntervals={5}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeCaption="Hora"
                                    dateFormat="HH:mm"
                                    className="form-control"
                                    placeholderText="Seleccionar hora"
                                    required={true}
                                    locale={"es"}
                                    onKeyDown={(e) => {
                                      e.preventDefault();
                                    }}
                                  />
                                  <span className="icon time" />
                                </div>
                              </div>
                              <div className="col-12 mb-4">
                                <div className="field-number">
                                  <button
                                    type="button"
                                    className="field-number__left"
                                    onClick={() =>
                                      setReservation((rsv) => ({
                                        ...rsv,
                                        attendees: rsv.attendees
                                          ? rsv.attendees - 1
                                          : 0,
                                      }))
                                    }
                                  >
                                    -
                                  </button>
                                  <input
                                    type="number"
                                    max={20}
                                    value={reservation?.attendees}
                                    className="form-control col-12"
                                    placeholder="No. de personas"
                                    onChange={(e: any) => {
                                      setReservation({
                                        ...reservation,
                                        attendees: e.target.value,
                                      });
                                    }}
                                  />
                                  <button
                                    type="button"
                                    className="field-number__right"
                                    onClick={() =>
                                      setReservation((rsv) => ({
                                        ...rsv,
                                        attendees: rsv.attendees
                                          ? rsv.attendees + 1
                                          : 1,
                                      }))
                                    }
                                  >
                                    +
                                  </button>
                                </div>
                              </div>
                              <div className="col-12 mb-4">
                                <input
                                  type="text"
                                  value={reservation?.comments}
                                  className="form-control col-12"
                                  placeholder="Añade comentarios o notas"
                                  onChange={(e: any) => {
                                    setReservation({
                                      ...reservation,
                                      comments: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                              {checkValidMovie() ? (
                                <div className="col-12">
                                  <button className="btn btn--type1 btn--100">
                                    Reservar
                                  </button>
                                </div>
                              ) : null}
                            </div>
                          </form>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}
              {successRequest && (
                <>
                  <figure className="col-8 mx-auto my-4 text-center">
                    <img
                      src="/assets/images/icon/check-success.svg"
                      alt="Success"
                      className="image"
                    />
                  </figure>
                  <div className="col-12 col-md-6 mx-auto mb-5 text-center">
                    <h3>
                      Recibimos tu solicitud, tu concierge estará en contacto a
                      la brevedad con tu información.
                    </h3>
                  </div>
                  <div className="col-8 col-md-3 mx-auto mb-5 clearfix">
                    <Link
                      to={"/Entertainment/cinemex"}
                      className="btn btn--type1 btn--100"
                    >
                      Continuar
                    </Link>
                  </div>
                </>
              )}
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default RequestMovieScreen;
